import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  .logo-txt {
    fill: #243650;
  }
`

/**
 * SVGアイコンコンポーネント
 * ロゴ
 */
const LogoSvg = (): JSX.Element => (
  <Wrapper className="logo">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 28">
      <path
        d="M7.657,25.663a.59.59,0,0,1-.51-.294L.078,13.127a.592.592,0,0,1,0-.589L7.147.294A.59.59,0,0,1,7.657,0H21.794a.584.584,0,0,1,.266.064L15.788,4.749l-8.32,6.214h-.04V.219h0V25.445h0V14.7H27.238a.638.638,0,0,1,.1.009.592.592,0,0,1,.315.163l.131.131a.59.59,0,0,1,.094.711L22.3,25.369a.588.588,0,0,1-.51.294Zm8.135-4.729L11.61,23.19l4.183-2.255L7.447,14.7ZM7.468,10.964l8.32-6.215,11.465,6.214H7.468Zm8.32-6.215L22.06.064a.59.59,0,0,1,.212.18.6.6,0,0,1,.032.05l.535.928L27.878,9.95a.588.588,0,0,1-.094.71l-.131.131a.6.6,0,0,1-.4.172Z"
        transform="translate(1 1.168)"
        fill="#3ec7b3"
      />
      <path
        d="M671.044,219.771V194.545l8.36,4.53,6.272-4.685a.592.592,0,0,0-.266-.064H671.273a.59.59,0,0,0-.51.294l-7.068,12.243a.589.589,0,0,0,0,.589l7.068,12.243a.59.59,0,0,0,.51.294H685.41a.585.585,0,0,0,.252-.057l-6.253-4.671Z"
        transform="translate(-662.616 -193.158)"
        fill="#3ec7b3"
      />
      <path
        d="M687.045,199.3l-8.359-4.53v10.744h.04Z"
        transform="translate(-670.257 -193.383)"
        fill="#7ad8cb"
      />
      <path
        d="M678.685,224.148v10.744l8.365-4.51-8.347-6.235Z"
        transform="translate(-670.257 -208.279)"
        fill="#7ad8cb"
      />
      <path
        d="M702.165,234.826l5.574-9.655a.589.589,0,0,0-.094-.711l-.131-.131a.589.589,0,0,0-.314-.163l-11.547,6.225,6.253,4.671A.587.587,0,0,0,702.165,234.826Z"
        transform="translate(-678.861 -208.289)"
        fill="#6cd4c5"
      />
      <path
        d="M678.722,224.148l8.346,6.235,11.547-6.225a.586.586,0,0,0-.1-.009Z"
        transform="translate(-670.276 -208.279)"
        fill="#9ae1d7"
      />
      <path
        d="M707.734,204.34l-5.039-8.727-.535-.928c-.01-.017-.021-.034-.033-.05a.589.589,0,0,0-.211-.18l-6.272,4.685,11.465,6.214a.59.59,0,0,0,.4-.172l.131-.131A.589.589,0,0,0,707.734,204.34Z"
        transform="translate(-678.856 -193.223)"
        fill="#6cd4c5"
      />
      <path
        d="M718.888,216.566h0Z"
        transform="translate(-690.634 -204.434)"
        fill="#7ad8cb"
      />
      <path
        d="M718.888,216.566h0Z"
        transform="translate(-690.634 -204.434)"
        fill="#fff"
        opacity="0.24"
      />
      <path
        d="M698.536,210.175h.016l-11.465-6.214-8.319,6.215Z"
        transform="translate(-670.299 -198.043)"
        fill="#9ae1d7"
      />
      <path
        d="M763.98,208.749l-10.268.352-.518-5.592,9.67-.368-.171-4.485-9.914.377-.468-5.047-4.47.414.445,4.8-8.628.328.171,4.485,8.872-.337.516,5.575-10.827.371.154,4.486,11.088-.38.579,6.254,4.47-.414-.555-5.993,10.007-.343Z"
        transform="translate(-700.532 -192.985)"
        className="logo-txt"
      />
      <path
        d="M824.861,215.406c-3.922,2.921-11.964,4.246-12.043,4.259l.711,4.432c.374-.06,9.206-1.51,14.014-5.091,5.865-4.369,7.146-14.723,7.2-15.161l-4.458-.524C829.994,205.773,828.457,212.728,824.861,215.406Z"
        transform="translate(-738.27 -197.718)"
        className="logo-txt"
      />
      <path
        d="M896.727,196.711H883.506a2.245,2.245,0,0,0-2.086,1.415,31.436,31.436,0,0,1-6.123,9.105h0v0l1.576,1.583,1.589,1.6a36.686,36.686,0,0,0,6.513-9.216h9.38c-.282,3.034-1.185,8.438-4.06,11.664-2.926,3.283-9.416,4-11.7,4h-.021l-.022,4.489h.028c.7,0,10.251-.1,15.061-5.5,5.292-5.937,5.328-16.455,5.327-16.9A2.244,2.244,0,0,0,896.727,196.711Z"
        transform="translate(-769.95 -194.367)"
        className="logo-txt"
      />
      <rect
        width="20.188"
        height="4.489"
        transform="translate(140.009 1.783)"
        className="logo-txt"
      />
      <path
        d="M965.737,212.874a2.243,2.243,0,0,0-1.666-.741h0l-22.313.018,0,4.489,19.421-.016a11.535,11.535,0,0,1-3.15,4.683c-2.87,2.493-9.337,3.184-11.584,3.241l.1,4.488a37.47,37.47,0,0,0,5.306-.588c4.031-.736,7.1-2,9.117-3.752a16.815,16.815,0,0,0,5.33-10.088A2.245,2.245,0,0,0,965.737,212.874Z"
        transform="translate(-803.649 -202.187)"
        className="logo-txt"
      />
      <rect
        width="8.898"
        height="4.264"
        transform="translate(73.252 3.695)"
        className="logo-txt"
      />
    </svg>
  </Wrapper>
)
export default LogoSvg
