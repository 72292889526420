/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

type Props = {
  description?: string
  lang?: string
  meta?: HTMLMetaElement[]
  title?: string
  ogUrl?: string
  ogType?: string
  ogTitle?: string
  ogDescription?: string
  ogSiteName?: string
  ogImage?: string
}

const SEO: React.FC<Props> = (props) => {
  const location = useLocation()
  const { description, lang, meta, title = '' } = props
  const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site?.siteMetadata?.description
  const defaultTitle = site?.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
        prefix: 'og: http://ogp.me/ns#',
      }}
      title={title || defaultTitle}
      titleTemplate={
        title ? (defaultTitle ? `%s | ${defaultTitle}` : undefined) : undefined
      }
      link={[
        {
          rel: 'canonical',
          href: `https://www.kintaicloud.jp${location.pathname}`,
        },
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: 'キンクラ,客先常駐,クラウド,勤怠管理システム',
        },
        {
          property: 'og:type',
          content: props.ogType ?? 'website',
        },
        {
          property: 'og:title',
          content: props.ogTitle ?? `${title} - ${defaultTitle}`,
        },
        {
          property: 'og:description',
          content: props.ogDescription,
        },
        {
          property: 'og:url',
          content: props.ogUrl ?? 'https://www.kintaicloud.jp/',
        },
        {
          property: 'og:image',
          content:
            props.ogImage ??
            'https://www.kintaicloud.jp/images/official/ogp/OGP.png',
        },
        {
          name: 'og:site_name',
          content: props.ogSiteName ?? defaultTitle,
        },
        {
          name: 'og:locale',
          content: 'ja_JP',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: `${title} - ${defaultTitle}`,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: 'https://www.kintaicloud.jp/images/official/ogp/OGP.png',
        },
        {
          name: 'thumbnail',
          content: 'https://www.kintaicloud.jp/images/thumnail.png',
        },
      ].concat(meta ?? [])}
    />
  )
}

export default SEO
