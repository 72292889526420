import { useLocation } from '@reach/router'

const useRouteParam = (path: string) => {
  const param = useLocation().search

  let newParam: string
  if (path.includes('?') === true) {
    newParam = param.replace('?', '&')
    return path + newParam
  }
  if (path.includes('#') === true) {
    return param + path
  }
  return path + param
}

export default useRouteParam
