/**
 * メディアクエリ用BreakPoint
 * 大きい方から順に指定していく
 */
const BreakPoints = {
  largest: '@media (max-width: 1366px)',
  xxLarge: '@media (max-width: 1200px)',
  xLarge: '@media (max-width: 1024px)',
  large: '@media (max-width: 800px)',
  medium: '@media (max-width: 640px)',
  small: '@media (max-width: 480px)',
  smallest: '@media (max-width: 375px)',
} as const
/**
 * largest：PC専用 ／シフト画面,勤務表画面
 * xxLarge：PC専用
 * xLarge：PC,タブレット,iPad
 * large：タブレット
 * medium：スマホ
 * small：低解像度スマホ
 * smallest：低解像度スマホ(縦持ち)
 */
export default BreakPoints
